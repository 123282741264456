// import logo from "../../assets/logo.jpeg"
import moment from "moment-timezone";
import {fCurrency} from "../../utils/formatNumber";

let fontSize = "0.7rem";

const style = `
  @media print{ 
    @page{
      size: portrait;  
      margin: 0px !important;
      padding: 0;
      width: 100%,
    }
    body {
      margin-bottom: 20mm; 
    }
  }
`;

export default function VerificationSlip(dataList, isSilentPrint = false, printerSize) {
    console.debug("VerificationSlip", { dataList, isSilentPrint, printerSize });

    let width = 360;
    fontSize = isSilentPrint ? "19px" : fontSize;
    if (printerSize) {
        if (printerSize == "80") {
            width = 560;
            fontSize = "29px"
        }
    }

    return (
        <html lang="sr">
        <head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <style type="text/css">{style}</style>
            <title>verification</title>
        </head>
        <body
            id="forPrint"
            style={{
                fontFamily: "Calibri",
                margin: 0,
                width: "100%",
                maxWidth: isSilentPrint ? "100%" : "80mm",
                justifyContent: "center",
                display: "inline-block",
                height: "auto",
                wordBreak: "break-word",
                fontSize: fontSize
            }}>
        {dataList.map((data, index) => (
            <div key={index} style={{ marginBottom: "20px", pageBreakAfter: "always" }}>
                <table style={{
                    width: isSilentPrint ? width : "auto",
                    margin: 0
                }}>
                    <tbody>
                    {data?.ticketLogo ?
                        <tr>
                            <td style={{textAlign: "center"}} colSpan={4}>
                                <img alt="logo" src={data?.ticketLogo} width={isSilentPrint ? width - 100 : "50%"}
                                     height="auto"/>
                            </td>
                        </tr> : <></>
                    }
                    <tr>
                        <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                            JGSP "NOVI SAD"
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                            AUTOBUSKA STANICA
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            ID:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data?.ticketId}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Važi uz kartu:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.service.id}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Operater:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.service.cashier}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Izdato:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {moment(data.service.salesTime).format("DD.MM.YYYY HH:mm:ss")}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Datum putovanja:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {moment(data?.departureTripTime).format("DD.MM.YYYY")}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Vreme:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {moment(data?.departureTripTime).format("HH:mm")}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Prevoznik:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.carrier}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            {data.depStationName}
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.arrStationName}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Peron:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.platformNumber}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Sedište:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.seat}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Naziv:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.service.name}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize, justifyContent: "space-between"}} colSpan={2}>
                            Cena:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {fCurrency(`${data.service.unitPrice}`)}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Količina:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {data.service.quantity}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Iznos bez pdv-a:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {fCurrency(`${data.service.baseAmount}`)}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Iznos pdv-a:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {fCurrency(`${data.service.pdv}`)}
                        </td>
                    </tr>
                    <tr style={{justifyContent: "space-between"}}>
                        <td style={{fontSize: fontSize}} colSpan={2}>
                            Ukupno:
                        </td>
                        <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                            {fCurrency(`${data.service.totalAmount}`)}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center" }} colSpan={4}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {data.qrCodeUrl && (
                                    <img
                                        style={{
                                            minWidth: isSilentPrint ? 360 : "40mm",
                                            minHeight: isSilentPrint ? 360 : "40mm",
                                            maxWidth: isSilentPrint ? 360 : "50mm",
                                            maxHeight: isSilentPrint ? 360 : "50mm",
                                        }}
                                        width={isSilentPrint ? 360 : "100%"}
                                        height={isSilentPrint ? 360 : "100%"}
                                        alt="qr code"
                                        src={data.qrCodeUrl}
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        ))}
        </body>
        </html>
    );
};
