import {
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useSelector} from "../../redux/store";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {DIRECTION_NAME, INVOICE_TYPE, PAYMENT_TYPE, SETTINGS_PAYMENTS, TRANSACTION_TYPE} from "../../constants";
import {LoadingButton, ToggleButtonGroup} from "@mui/lab";
import MethodButton from "../../components/MethodButton";
import {alpha} from "@mui/material/styles";
import {fCurrency} from "../../utils/formatNumber";
import moment from "moment/moment";
import {Invoice} from "../../helper/invoice/Invoice";
import {calculateBasicOfItem, validateValues} from "../../helper/other";
import {issueInvoiceVerificationApi} from "../../api/invoice";
import uuidv4 from "../../utils/uuidv4";
import {allSeats} from "../../api/trip";
import {getCashierName} from "../../utils/other";

export default function VerificationDialog({freeSeats, open, onClose, onSuccess, invoiceLine}) {

    const {enqueueSnackbar} = useSnackbar();
    const {allItemsList} = useSelector(state => state.items);
    const {roundedPrice,} = useSelector(state => state.userSettings);
    const {currentTaxRates} = useSelector(((state) => state.taxCore));
    const {settings, userImage, silentPrint, printerSize, ticketLogo} = useSelector(state => state.settings);

    const [selectedPayments, setSelectedPayments] = useState([]);
    const [exchange, setExchange] = useState(0);
    const [cardNumber, setCardNumber] = useState();
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [availableSeats, setAvailableSeats] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState();
    const [selectedSeat, setSelectedSeat] = useState();
    const [totalPassengers, setTotalPassengers] = useState(1);
    const [openSelect, setOpenSelect] = useState(false);

    const item = allItemsList.find(item => item.includeOnVerification === true);
    const total = item?.price || 0

    const roundTotal = (roundedPrice ? Math.ceil(total / 10) * 10 : total) * Number(totalPassengers)

    let showExchange = selectedPayments.length === 1 && selectedPayments[0].paymentType === PAYMENT_TYPE.cash && settings.change;
    const filteredPayments = SETTINGS_PAYMENTS.filter(value => !!settings[value.name]);
    const handleChange = (event) => {
        setTotalPassengers(event.target.value);
    };

    const handleClose = () => {
        setOpenSelect(false);
    };

    const handleOpen = () => {
        setOpenSelect(true);
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSelectedPayments([{
                paymentType: PAYMENT_TYPE.cash,
                amount: roundTotal
            }]);
            setExchange(0);
        }
        return () => isMounted = false;
    }, [roundTotal]);

    useEffect(() => {
        if (invoiceLine?.alSeatNumbered) {
            allSeats({
                id: invoiceLine.tripId,
                date: moment(invoiceLine.departureTime).format("yyyy-MM-DD"),
                depId: invoiceLine.depStationId,
                arrId: invoiceLine.arrStationId,
            }).then(({data}) => {
                const uniqueVehicles = [...new Set(data.map((seat) => seat.vehicleId))];
                setVehicles(uniqueVehicles);
                setAvailableSeats(data);
            }).catch(() => {
                enqueueSnackbar("Nema slobodnih mesta", {variant: "info"});
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [invoiceLine]);
    const handleMethodClick = (method, isDouble) => {
        if (isDouble) {
            setSelectedPayments([{
                paymentType: method.value,
                amount: roundTotal
            }]);
        } else {
            let arr = [...selectedPayments];
            let index = arr.findIndex(localMethod => localMethod.paymentType === method.value);
            if (index === -1) {
                arr.push({
                    paymentType: method.value,
                    amount: 0
                });
            } else {
                arr.splice(index, 1);
            }
            if (arr.length > 1) {
                arr = arr.map(value => ({
                    ...value,
                    amount: 0
                }));
            } else {
                arr = arr.map(value => ({
                    ...value,
                    amount: roundTotal
                }));
            }
            setSelectedPayments(arr);
        }
        setExchange(0);
    };

    const handleVehicleChange = (event) => {
        setSelectedVehicleId(event.target.value);
        setSelectedSeat(""); // Reset seat selection when vehicle changes
    };

    const handleSeatChange = (event) => {
        setSelectedSeat(event.target.value);
    };

    const filteredSeats = availableSeats.filter(
        (seat) =>
            seat.vehicleId === selectedVehicleId &&
            seat.alId !== null &&
            !seat.occupied &&
            !seat.reserved
    );

    const handleChangeAmount = (event, index) => {
        const {name, value} = event.target;
        let arr = [...selectedPayments];
        let sumAll = arr.filter(localMethod => localMethod.paymentType !== name).reduce((acc, {amount}) => acc + amount, 0);
        let amount = Number(Number(value).toFixed(2));
        let newAmount = Number(Number(sumAll + amount <= roundTotal ? amount : roundTotal - sumAll).toFixed(2));
        arr[index] = {
            ...arr[index],
            amount: showExchange ? amount : newAmount
        };
        if (showExchange) {
            setExchange(Number(Number(amount - roundTotal).toFixed(2)));
        }
        setSelectedPayments(arr);
    };

    const handleChangeCardNumber = (event) => {
        const {value} = event.target;
        setCardNumber(value)
    }

    const handleVerificationCard = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();

            if (!item) {
                return enqueueSnackbar("Ne postoji proizvod/usluga za izdavanje!", {variant: "warning"});
            }

            let sumAll = selectedPayments.reduce((acc, {amount}) => acc + amount, 0);
            if (showExchange) {
                sumAll = sumAll - exchange;
            }
            if (sumAll !== roundTotal) {
                return enqueueSnackbar("Zbir plaćanja mora biti isti kao i ukupan iznos", {variant: "warning"});
            }

            let localExchange = exchange < 0 ? 0 : exchange;
            let payment = selectedPayments;
            if (selectedPayments.length === 1) {
                payment = selectedPayments.map(value => ({
                    ...value,
                    amount: roundTotal
                }));
            }

            // Kreiramo niz ID-jeva u skladu sa totalPassengers
            const idArray = Array.from({length: totalPassengers}, () =>
                cardNumber ? cardNumber : Math.floor(10000 + Math.random() * 90000)
            );

            const normalizedTickets = idArray.map((id, index) => ({
                id,
                price: item.price,
                isReturnTicket: false,
                trip: {
                    id: invoiceLine.tripId
                },
                tripId: invoiceLine.tripId,
                departureStation: {
                    id: invoiceLine.depStationId
                },
                arrivalStation: {
                    id: invoiceLine.arrStationId
                },
                journeyDate: moment(invoiceLine.departureTripTime).toISOString(),
                cashier: getCashierName(),
                ticketType: null,
                itemName: item.name,
                labels: JSON.stringify(item.labels),
                seatReserved: selectedSeat || null,
                vehicleId: selectedVehicleId || null,
                passenger: {
                    passengerUid: uuidv4(),
                    passengerName: null,
                    passengerLastName: null,
                    passengerTelephone: null,
                    passengerEmail: null,
                    passengerDocumentNumber: null,
                },
            }));

            const base = calculateBasicOfItem({
                ...item,
                unitPrice: item.price,
                quantity: 1
            }, currentTaxRates);

            const invoice = new Invoice();
            invoice.setItems(idArray.map((id, index) => ({
                ...item,
                totalAmount: item.price,
                name: item.name,
                quantity: 1,
                labels: [item.vat],
                unitPrice: item.price,
                baseAmount: base,
                pdv: item.price - base,
                cashier: getCashierName(),
                id,
                salesTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            })));

            invoice.setRoundedPrice = roundedPrice;
            invoice.setPayments(payment);
            invoice.setInvoiceType(INVOICE_TYPE.normal);
            invoice.setTransactionType(TRANSACTION_TYPE.sale);
            invoice.setSilentPrint(silentPrint);
            invoice.setPrinterSize(printerSize);
            invoice.setLogo(userImage);
            invoice.setTicketLogo(ticketLogo);
            invoice.setExchange(localExchange || 0);
            invoice.setPrintLogoInvoice(settings?.printLogoInvoice || "");
            invoice.setPrintLogoTicket(settings?.printLogoTicket || "");

            const internalData = validateValues({
                client: "web",
                selectedLine: invoiceLine.lineId || null,
                issuedFor: invoiceLine.issuedFor?.tin || null,
                startStation: invoiceLine.depStationId !== undefined ? invoiceLine.depStationId : null,
                endStation: invoiceLine.arrStationId !== undefined ? invoiceLine.arrStationId : null,
                departureTripTime: invoiceLine.departureTripTime !== undefined ? invoiceLine.departureTripTime : null,
                cardNumber: idArray, // Koristimo niz ID-jeva ovde
                directionType: DIRECTION_NAME[1],
            });

            if (internalData) {
                invoice.setInternalData(internalData);
            }

            const invoiceResponse = (await issueInvoiceVerificationApi({
                invoice: {
                    internalData: invoice.internalData,
                    ...invoice.invoice
                },
                tickets: normalizedTickets,
                isOnline: false
            })).data;

            await invoice.printInvoice(invoice.invoice, invoiceResponse.taxCoreInvoice);

            const verificationOBJList = invoiceResponse.tickets.map((o, index) => ({
                arrStationName: invoiceLine.arrStationName,
                depStationName: invoiceLine.depStationName,
                platformNumber: invoiceLine.platformNumber,
                carrier: invoiceLine.carrier,
                cardNumber: idArray[index],
                ticketId: o.id,
                departureTripTime: invoiceLine.departureTripTime,
                seat: invoiceLine.alSeatNumbered ? o.seatReserved : "NN",
            }))

            console.log(verificationOBJList, "verificationOBJListverificationOBJList")
            await invoice.printVerification(verificationOBJList);

            if (onSuccess) {
                onSuccess();
            }
            onClose();
        } catch (error) {
            console.error("handleSubmitPaymentDialog", error);
            if (typeof error === "string") {
                return enqueueSnackbar(error, {variant: "error"});
            }
            return enqueueSnackbar(error?.message, {variant: "error"});
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog open={open} onClose={loading ? null : onClose}>
            <form>
                <DialogTitle>
                    Overa karte
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{mt: 2}} size="small" fullWidth>
                        <InputLabel id="total-passengers">Broj putnika</InputLabel>
                        <Select
                            labelId="total-passengers-label"
                            id="total-passengers-controlled-open-select"
                            open={openSelect}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={totalPassengers}
                            label="Broj putnika"
                            onChange={handleChange}
                        >
                            {Array.from({length: freeSeats}, (_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/*<TextField*/}
                    {/*    sx={{my: 2}}*/}
                    {/*    label="Broj karte"*/}
                    {/*    fullWidth={true}*/}
                    {/*    value={cardNumber}*/}
                    {/*    onChange={handleChangeCardNumber}*/}
                    {/*/>*/}
                    {invoiceLine?.alSeatNumbered &&
                        <Stack direction="row" spacing={1} my={1}>
                            <FormControl fullWidth>
                                <InputLabel>Vozilo</InputLabel>
                                <Select
                                    size="small"
                                    value={selectedVehicleId}
                                    onChange={handleVehicleChange}
                                    label="Vozilo"
                                >
                                    {vehicles.map((vehicleId) => (
                                        <MenuItem key={vehicleId} value={vehicleId}>
                                            {`Vozilo ${vehicleId}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel>Sedište</InputLabel>
                                <Select
                                    size="small"
                                    disabled={!selectedVehicleId}
                                    value={selectedSeat}
                                    onChange={handleSeatChange}
                                    label="Sedište"
                                >
                                    {filteredSeats.map((seat) => (
                                        <MenuItem key={seat.seat} value={seat.seat}>
                                            {`Sedište ${seat.seat}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    }

                    <Divider sx={{borderStyle: "dashed", mb: 1}}>
                        <Chip label={"Načini plaćanja"} variant="outlined"/>
                    </Divider>
                    <ToggleButtonGroup
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}>
                        {filteredPayments.map((method, index) =>
                            <MethodButton
                                key={`payment-method-${method.name}-${index}`}
                                onClick={handleMethodClick}
                                method={method}
                                selectedPayments={selectedPayments}/>
                        )}
                    </ToggleButtonGroup>
                    <Card sx={{
                        //textTransform: "capitalize",
                        color: (theme) => (theme.palette.mode === "light" ? "primary.darker" : "primary.lighter"),
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
                        paddingX: 3,
                        paddingY: 2
                    }}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="button" color="dimgrey">
                                Ukupan iznos za uplatu: {fCurrency(roundTotal.toString())} RSD
                            </Typography>
                            <Stack direction="row" spacing={1}>
                                {selectedPayments.map((value, index) =>
                                    <TextField
                                        size="small"
                                        autoFocus={index === 0}
                                        key={`selected-payment-${index}-${value.paymentType}`}
                                        sx={{
                                            maxWidth: 150
                                        }}
                                        label={getLabel(value.paymentType)}
                                        value={value.amount}
                                        name={value.paymentType}
                                        onChange={(event) => handleChangeAmount(event, index)}
                                    />
                                )}
                            </Stack>
                            {exchange > 0 &&
                                <Typography variant="button" color="dimgrey">
                                    Kusur: {fCurrency(exchange.toString())}
                                </Typography>}
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="outlined" color="inherit" onClick={onClose}>
                        Otkaži [ESC]
                    </Button>
                    <LoadingButton loading={loading} onClick={handleVerificationCard} variant="contained">
                        Fiskalizuj
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const getLabel = (method) => {
    return SETTINGS_PAYMENTS.find(localMethod => localMethod.value === method).displayName;
};
