import {Navigate, useRoutes} from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import {PATH_AFTER_LOGIN} from "../config";
import {
    ActionsEditPage,
    ActionsList,
    ActionsListNew,
    ActionsValueEditPage,
    ActionsValueListNew,
    AdditionalIncomePage,
    AllotmentEditPage,
    AllotmentList,
    AllotmentListPreviewPage,
    AllotmentNew,
    AllTicketsPage,
    BarriersPage,
    BusEditPage,
    BusList,
    BusNew,
    BusTickets,
    CalculationOfIncomeForStationPage,
    CarrierReport,
    CarrierReportDetailed,
    CarrierReportEdit,
    CarrierReportPrivilegesTickets,
    CarrierReportReturnTickets,
    CarrierReportTickets,
    CarriersEditPage,
    CarriersList,
    CarriersNew,
    CommentsEditPage,
    CommentsList,
    CommentsListPreviewPage,
    CommentsNew,
    CommissionCalculation,
    CurrentAllotmentListPreviewPage,
    CustomersEditPage,
    CustomersList,
    CustomersNew,
    DeparturesLinked,
    DeparturesLinkedList,
    DeparturesList,
    DeparturesListEditPage,
    DeparturesListNew,
    DeparturesListPreviewPage,
    DeparturesStatus,
    DisabilitiesList,
    DisabilityEditPage,
    DisabilityNew,
    DiscountsEditPage,
    DiscountsList,
    DiscountsListPreviewPage,
    DiscountsNew,
    DriveParkListPreviewPage,
    HolidaysEditPage,
    HolidaysList,
    HolidaysNew,
    HomePage,
    InsuranceEditPage,
    InsuranceList,
    InsuranceNew,
    InvoicePage,
    ItemsEditPage,
    ItemsNewPage,
    ItemsPage,
    LinesList,
    LinesListEditPage,
    LinesListNew,
    LinesListPreviewPage,
    LocationsList,
    LocationsListEditPage,
    LocationsListNew,
    Login,
    MonthlyTicketsList,
    MonthlyTicketsListEditPage,
    MonthlyTicketsListNew,
    OperatorsEditPage,
    OperatorsList,
    OperatorsNew,
    OtherOtpravnikPage,
    OtpravnikEditPage,
    OtpravnikList,
    OtpravnikNew,
    OtpravnikNewBIS,
    OverviewTicketAndOtherPage,
    ParkingList,
    PeronizationList,
    PeronizationNew,
    PeronizationPrice,
    PlatformBusynessPage,
    PlatformsEditPage,
    PlatformsList,
    PlatformsNew,
    PreviewCancelledTripsHistory,
    PreviewDeparturesLinked,
    PreviewRatesHistory,
    PreviewTimes,
    PriceList,
    PriceListEditPage,
    PriceListNew,
    PriceListPreviewPage,
    PriceListStationEditPage,
    PriceListStationNew,
    PriceStationsList,
    PrivilegesEditPage,
    PrivilegesList,
    PrivilegesNew,
    PublisherPage,
    ReportsPage,
    ScheduledDepAndArrList,
    SettingsPage,
    StationList,
    StationListEditPage,
    StationListNew,
    StationServicesPage,
    TimesList,
    UnusedCameraEvents,
    UserPathPrivilegesPage,
    ValidatedTicketsPage,
} from "./elements";
import AuthGuard from "../auth/AuthGuard";
import {PATH_AUTH, PATH_DASHBOARD} from "./path";
import GuestGuard from "../auth/GuestGuard";
import ActionsValue from "../pages/Actions/ActionsValue";


export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: PATH_AUTH.login,
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    )
                }
            ]
        },
        {
            path: "",
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: PATH_DASHBOARD.general.app, element: <HomePage/>},
                {path: PATH_DASHBOARD.admin.routes, element: <UserPathPrivilegesPage/>},
                {path: PATH_DASHBOARD.general.stationServices, element: <StationServicesPage/>},
                {
                    path: PATH_DASHBOARD.reports.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.reports.overview} replace/>, index: true},
                        {path: PATH_DASHBOARD.reports.overview, element: <ReportsPage/>},
                        {path: PATH_DASHBOARD.reports.additionalIncome, element: <AdditionalIncomePage/>},
                        {path: PATH_DASHBOARD.reports.overviewTicketAndOther, element: <OverviewTicketAndOtherPage/>}
                    ]
                },
                {path: PATH_DASHBOARD.general.invoices, element: <InvoicePage/>},
                {path: PATH_DASHBOARD.general.busTickets, element: <BusTickets/>},
                {path: PATH_DASHBOARD.general.settings, element: <SettingsPage/>},
                {path: PATH_DASHBOARD.preview.priceList, element: <PriceListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.linesList, element: <LinesListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.departuresList, element: <DeparturesListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.timesList, element: <PreviewTimes/>},
                {path: PATH_DASHBOARD.preview.discountsList, element: <DiscountsListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.driveParkList, element: <DriveParkListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.commentsList, element: <CommentsListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.allotments, element: <AllotmentListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.currentAllotments, element: <CurrentAllotmentListPreviewPage/>},
                {path: PATH_DASHBOARD.preview.previewDeparturesLinked, element: <PreviewDeparturesLinked/>},
                {path: PATH_DASHBOARD.preview.cancelledTripsHistory, element: <PreviewCancelledTripsHistory/>},
                {path: PATH_DASHBOARD.preview.ratesHistory, element: <PreviewRatesHistory/>},
                {
                    path: PATH_DASHBOARD.discounts.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.discounts.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.discounts.list, element: <DiscountsList/>},
                        {path: PATH_DASHBOARD.discounts.new, element: <DiscountsNew/>},
                        {path: ":name/edit", element: <DiscountsEditPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.privileges.root,
                    children: [
                        {path: PATH_DASHBOARD.privileges.list, element: <PrivilegesList/>},
                        {path: PATH_DASHBOARD.privileges.new, element: <PrivilegesNew/>},
                        {path: ":name/edit", element: <PrivilegesEditPage/>},
                        {path: PATH_DASHBOARD.privileges.listDisabilities, element: <DisabilitiesList/>},
                        {path: PATH_DASHBOARD.privileges.newDisability, element: <DisabilityNew/>},
                        {path: ":name/editDisability", element: <DisabilityEditPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.bus.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.bus.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.bus.list, element: <BusList/>},
                        {path: PATH_DASHBOARD.bus.new, element: <BusNew/>},
                        {path: ":name/edit", element: <BusEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.priceList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.priceList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.priceList.list, element: <PriceList/>},
                        {path: PATH_DASHBOARD.priceList.new, element: <PriceListNew/>},
                        {path: PATH_DASHBOARD.priceList.newStation, element: <PriceListStationNew/>},
                        {path: PATH_DASHBOARD.priceList.stationsList, element: <PriceStationsList/>},
                        {path: PATH_DASHBOARD.priceList.stationsServicesList, element: <ItemsPage/>},
                        {path: PATH_DASHBOARD.priceList.stationsServicesAdd, element: <ItemsNewPage/>},
                        {path: ":name/edit", element: <PriceListEditPage/>},
                        {path: ":name/edit-station", element: <PriceListStationEditPage/>},
                        {path: ":name/edit-station-service", element: <ItemsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.stationList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.priceList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.stationList.list, element: <StationList/>},
                        {path: PATH_DASHBOARD.stationList.new, element: <StationListNew/>},
                        {path: ":name/edit", element: <StationListEditPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.locationList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.locationList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.locationList.list, element: <LocationsList/>},
                        {path: PATH_DASHBOARD.locationList.new, element: <LocationsListNew/>},
                        {path: ":name/edit", element: <LocationsListEditPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.linesList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.linesList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.linesList.list, element: <LinesList/>},
                        {path: PATH_DASHBOARD.linesList.new, element: <LinesListNew/>},
                        {path: ":name/edit", element: <LinesListEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.departuresList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.departuresList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.departuresList.list, element: <DeparturesList/>},
                        {path: PATH_DASHBOARD.departuresList.new, element: <DeparturesListNew/>},
                        {path: PATH_DASHBOARD.departuresList.linked, element: <DeparturesLinked/>},
                        {path: PATH_DASHBOARD.departuresList.departuresLinkedList, element: <DeparturesLinkedList/>},
                        {path: ":name/edit", element: <DeparturesListEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.allotment.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.allotment.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.allotment.list, element: <AllotmentList/>},
                        {path: PATH_DASHBOARD.allotment.new, element: <AllotmentNew/>},
                        {path: ":name/edit", element: <AllotmentEditPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.timesList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.timesList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.timesList.list, element: <TimesList/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.actions.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.actions.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.actions.list, element: <ActionsList/>},
                        {path: PATH_DASHBOARD.actions.new, element: <ActionsListNew/>},
                        {path: ":name/edit", element: <ActionsEditPage/>},
                        {path: PATH_DASHBOARD.actions.actionValueList, element: <ActionsValue/>},
                        {path: PATH_DASHBOARD.actions.actionValueNew, element: <ActionsValueListNew/>},
                        {path: ":name/actionValueListEdit", element: <ActionsValueEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.monthlyTicketsList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.monthlyTicketsList.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.monthlyTicketsList.list, element: <MonthlyTicketsList/>},
                        {path: PATH_DASHBOARD.monthlyTicketsList.new, element: <MonthlyTicketsListNew/>},
                        {path: ":name/edit", element: <MonthlyTicketsListEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.customers.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.customers.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.customers.list, element: <CustomersList/>},
                        {path: PATH_DASHBOARD.customers.new, element: <CustomersNew/>},
                        {path: ":name/edit", element: <CustomersEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.carriers.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.carriers.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.carriers.list, element: <CarriersList/>},
                        {path: PATH_DASHBOARD.carriers.new, element: <CarriersNew/>},
                        {path: ":name/edit", element: <CarriersEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.otpravnik.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.otpravnik.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.otpravnik.list, element: <OtpravnikList/>},
                        {path: PATH_DASHBOARD.otpravnik.other, element: <OtherOtpravnikPage/>},
                        {path: PATH_DASHBOARD.otpravnik.new, element: <OtpravnikNew/>},
                        {path: ":name/edit", element: <OtpravnikEditPage/>},
                        {path: PATH_DASHBOARD.otpravnik.unusedCameraEvents, element: <UnusedCameraEvents/>},
                        {path: PATH_DASHBOARD.otpravnik.publisher, element: <PublisherPage/>},
                        {path: PATH_DASHBOARD.otpravnik.newBIS, element: <OtpravnikNewBIS/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.holidays.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.holidays.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.holidays.list, element: <HolidaysList/>},
                        {path: PATH_DASHBOARD.holidays.new, element: <HolidaysNew/>},
                        {path: ":name/edit", element: <HolidaysEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.scheduledDepAndArr.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.scheduledDepAndArr.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.scheduledDepAndArr.list, element: <ScheduledDepAndArrList/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.peronization.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.peronization.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.peronization.list, element: <PeronizationList/>},
                        {path: PATH_DASHBOARD.peronization.price, element: <PeronizationPrice/>},
                        {path: PATH_DASHBOARD.peronization.new, element: <PeronizationNew/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.parking.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.parking.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.parking.list, element: <ParkingList/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.carrierReport.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.carrierReport.sumarized} replace/>, index: true},
                        {path: PATH_DASHBOARD.carrierReport.sumarized, element: <CarrierReport/>},
                        {path: PATH_DASHBOARD.carrierReport.edit, element: <CarrierReportEdit/>},
                        {path: PATH_DASHBOARD.carrierReport.detailed, element: <CarrierReportDetailed/>},
                        {
                            path: PATH_DASHBOARD.carrierReport.privilegesTickets,
                            element: <CarrierReportPrivilegesTickets/>
                        },
                        {path: PATH_DASHBOARD.carrierReport.returnTickets, element: <CarrierReportReturnTickets/>},
                        {path: PATH_DASHBOARD.carrierReport.tickets, element: <CarrierReportTickets/>},
                        {path: PATH_DASHBOARD.carrierReport.validatedTickets, element: <ValidatedTicketsPage/>},
                        {path: PATH_DASHBOARD.carrierReport.allTickets, element: <AllTicketsPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.commissionCalculation.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.commissionCalculation.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.commissionCalculation.list, element: <CommissionCalculation/>},
                        {
                            path: PATH_DASHBOARD.commissionCalculation.incomeForStation,
                            element: <CalculationOfIncomeForStationPage/>
                        },
                    ]
                },
                {
                    path: PATH_DASHBOARD.insurance.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.insurance.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.insurance.list, element: <InsuranceList/>},
                        {path: PATH_DASHBOARD.insurance.new, element: <InsuranceNew/>},
                        {path: ":name/edit", element: <InsuranceEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.platforms.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.platforms.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.platforms.list, element: <PlatformsList/>},
                        {path: PATH_DASHBOARD.platforms.new, element: <PlatformsNew/>},
                        {path: PATH_DASHBOARD.platforms.busyness, element: <PlatformBusynessPage/>},
                        {path: '/platforms/busyness/:id', element: <PlatformBusynessPage/>},
                        {path: ":id/edit", element: <PlatformsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.operators.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.operators.list}/>, index: true},
                        {path: PATH_DASHBOARD.operators.list, element: <OperatorsList/>},
                        {path: PATH_DASHBOARD.operators.new, element: <OperatorsNew/>},
                        {path: ":name/edit", element: <OperatorsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.comments.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.comments.list}/>, index: true},
                        {path: PATH_DASHBOARD.comments.list, element: <CommentsList/>},
                        {path: PATH_DASHBOARD.comments.new, element: <CommentsNew/>},
                        {path: ":name/edit", element: <CommentsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.departuresStatusList.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.departuresStatusList.list}/>, index: true},
                        {path: PATH_DASHBOARD.departuresStatusList.list, element: <DeparturesStatus/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.barriers.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.barriers.list}/>, index: true},
                        {path: PATH_DASHBOARD.barriers.list, element: <BarriersPage/>},
                        // {path: PATH_DASHBOARD.barriers.partners, element: <BarriersExternalPartnersPage/>},
                    ]
                },

                // {
                //     path: PATH_DASHBOARD.items.root,
                //     children: [
                //         {element: <Navigate to={ PATH_DASHBOARD.priceList.stationsServicesList} replace/>, index: true},
                //         {path:  PATH_DASHBOARD.priceList.stationsServicesList, element: <ServicesList/>},
                //         {path: PATH_DASHBOARD.items.new, element: <ServicesNew/>},
                //         {path: ":name/edit", element: <ServicesEditPage/>}
                //     ]
                // },
            ]
        },
    ]);
}


