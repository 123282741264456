import {lazy, Suspense} from "react";
// components
import LoadingScreen from "../components/loading-screen";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props} />
        </Suspense>
    );

// ----------------------------------------------------------------------

// AUTHENTICATION
export const Login = Loadable(lazy(() => import("../pages/auth/LoginPage")));

// PAGES
export const HomePage = Loadable(lazy(() => import("../pages/Home")));
export const StationServicesPage = Loadable(lazy(() => import("../pages/StationServices")));
export const ReportsPage = Loadable(lazy(() => import("../pages/ReportNew")));
export const AdditionalIncomePage = Loadable(lazy(() => import("../pages/ReportNew/AdditionalIncomeReport")));
export const OverviewTicketAndOtherPage = Loadable(lazy(() => import("../pages/ReportNew/OverviewTicketAndOther")));
export const InvoicePage = Loadable(lazy(() => import("../pages/Invoices")));
export const BusTickets = Loadable(lazy(() => import("../pages/BusTickets")));

// ITEMS
export const ItemsPage = Loadable(lazy(() => import("../pages/Items")));
export const ItemsNewPage = Loadable(lazy(() => import("../pages/Items/ItemsNew")));
export const ItemsEditPage = Loadable(lazy(() => import("../pages/Items/ItemsEdit")));

// DISCOUNTS
// ZA SVE
export const DiscountsList = Loadable(lazy(() => import("../pages/Discounts/DiscountsGlobal")));
export const DiscountsNew = Loadable(lazy(() => import("../pages/Discounts/DiscountsGlobal/DiscountsNew")));
export const DiscountsEditPage = Loadable(lazy(() => import("../pages/Discounts/DiscountsGlobal/DiscountEditPage")));


// DISABILITIES

export const DisabilitiesList = Loadable(lazy(() => import("../pages/Disability")));
export const DisabilityNew = Loadable(lazy(() => import("../pages/Disability/DisabilityNew")));
export const DisabilityEditPage = Loadable(lazy(() => import("../pages/Disability/DisabilityEditPage")));


// PRIVILAGES

export const PrivilegesList = Loadable(lazy(() => import("../pages/Privileges")));
export const PrivilegesNew = Loadable(lazy(() => import("../pages/Privileges/PrivilegesNew")));
export const PrivilegesEditPage = Loadable(lazy(() => import("../pages/Privileges/PrivilegesEditPage")));

// BUS
export const BusList = Loadable(lazy(() => import("../pages/Bus")));
export const BusNew = Loadable(lazy(() => import("../pages/Bus/BusNew")));
export const BusEditPage = Loadable(lazy(() => import("../pages/Bus/BusEditPage")));

// PHOLIDAYS
export const HolidaysList = Loadable(lazy(() => import("../pages/Holidays")));
export const HolidaysNew = Loadable(lazy(() => import("../pages/Holidays/HolidaysNew")));
export const HolidaysEditPage = Loadable(lazy(() => import("../pages/Holidays/HolidaysEditPage")));

// PRICE LIST
export const PriceList = Loadable(lazy(() => import("../pages/PriceList")));
export const PriceStationsList = Loadable(lazy(() => import("../pages/PriceList/PriceStationsList")));
export const PriceListNew = Loadable(lazy(() => import("../pages/PriceList/PriceListNew")));
export const PriceListStationNew = Loadable(lazy(() => import("../pages/PriceList/PriceStationsList/PriceListStationNew")));
export const PriceListEditPage = Loadable(lazy(() => import("../pages/PriceList/PriceListEditPage")));
export const PriceListStationEditPage = Loadable(lazy(() => import("../pages/PriceList/PriceStationsList/PriceListStationEditPage")));

// STATION LIST
export const StationList = Loadable(lazy(() => import("../pages/Stations")));
export const StationListNew = Loadable(lazy(() => import("../pages/Stations/StationListNew")));
export const StationListEditPage = Loadable(lazy(() => import("../pages/Stations/StationListEditPage")));

// LOCATIONS LIST
export const LocationsList = Loadable(lazy(() => import("../pages/Locations")));
export const LocationsListNew = Loadable(lazy(() => import("../pages/Locations/LocationsListNew")));
export const LocationsListEditPage = Loadable(lazy(() => import("../pages/Locations/LocationsListEditPage")));

// LINES LIST
export const LinesList = Loadable(lazy(() => import("../pages/Lines")));
export const LinesListNew = Loadable(lazy(() => import("../pages/Lines/LinesListNew")));
export const LinesListEditPage = Loadable(lazy(() => import("../pages/Lines/LinesListEditPage")));

// DEPARTURES LIST
export const DeparturesList = Loadable(lazy(() => import("../pages/Departures")));
export const DeparturesListNew = Loadable(lazy(() => import("../pages/Departures/DeparturesListNew")));
export const DeparturesListEditPage = Loadable(lazy(() => import("../pages/Departures/DeparturesListEditPage")));
export const DeparturesLinked = Loadable(lazy(() => import("../pages/Departures/DeparturesLinked")));
export const DeparturesLinkedList = Loadable(lazy(() => import("../pages/Departures/DeparturesLinkedList")));

// ALLOTMENTS
export const AllotmentList = Loadable(lazy(() => import("../pages/Allotment")));
export const AllotmentNew = Loadable(lazy(() => import("../pages/Allotment/AllotmentNew")));
export const AllotmentEditPage = Loadable(lazy(() => import("../pages/Allotment/AllotmentEditPage")));

// TIMES LIST
export const TimesList = Loadable(lazy(() => import("../pages/Times")));
// ACTIONS LIST
export const ActionsList = Loadable(lazy(() => import("../pages/Actions")));
export const ActionsListNew = Loadable(lazy(() => import("../pages/Actions/ActionsNew")));
export const ActionsEditPage = Loadable(lazy(() => import("../pages/Actions/ActionsEditPage")));

// ACTIONS VALUES
// export const ActionsValueList = Loadable(lazy(() => import("../pages/Actions/ActionsValue")));
export const ActionsValueListNew = Loadable(lazy(() => import("../pages/Actions/ActionsValue/ActionsValueNew")));
export const ActionsValueEditPage = Loadable(lazy(() => import("../pages/Actions/ActionsValue/ActionsValueEditPage")));

// MONTHLY LIST
export const MonthlyTicketsList = Loadable(lazy(() => import("../pages/MonthlyTickets")));
export const MonthlyTicketsListNew = Loadable(lazy(() => import("../pages/MonthlyTickets/MonthlyTicketsNew")));
export const MonthlyTicketsListEditPage = Loadable(lazy(() => import("../pages/MonthlyTickets/MonthlyTicketsListEditPage")));

// CUSTOMERS
export const CustomersList = Loadable(lazy(() => import('../pages/Customers/CustomersList')))
export const CustomersNew = Loadable(lazy(() => import('../pages/Customers/CustomersNew')))
export const CustomersEditPage = Loadable(lazy(() => import('../pages/Customers/CustomersEditPage')))

// CARRIERS
export const CarriersList = Loadable(lazy(() => import('../pages/Carriers/CarriersList')))
export const CarriersNew = Loadable(lazy(() => import('../pages/Carriers/CarriersNew')))
export const CarriersEditPage = Loadable(lazy(() => import('../pages/Carriers/CarriersEditPage')))

// INSURANCE
export const InsuranceList = Loadable(lazy(() => import('../pages/Insurance/InsuranceList')))
export const InsuranceNew = Loadable(lazy(() => import('../pages/Insurance/InsuranceNew')))
export const InsuranceEditPage = Loadable(lazy(() => import('../pages/Insurance/InsuranceEditPage')))

// PLATFORMS
export const PlatformsList = Loadable(lazy(() => import('../pages/Platforms')))
export const PlatformsNew = Loadable(lazy(() => import('../pages/Platforms/PlatformsNew')))
export const PlatformsEditPage = Loadable(lazy(() => import('../pages/Platforms/PlatformsEditPage')))
export const PlatformBusynessPage = Loadable(lazy(() => import('../pages/Platforms/busyness/PlatformBusyness')))

// SERVICES
// export const ServicesList = Loadable(lazy(() => import('../pages/Services')))
// export const ServicesNew = Loadable(lazy(() => import('../pages/Services/ServiceNew')))
// export const ServicesEditPage = Loadable(lazy(() => import('../pages/Services/ServicesEditPage')))

// OTPRAVNIK
export const UnusedCameraEvents = Loadable(lazy(() => import('../pages/Optravnik/UnusedCameraEvents')))
export const OtpravnikList = Loadable(lazy(() => import('../pages/Optravnik')))
export const OtpravnikNew = Loadable(lazy(() => import('../pages/Optravnik/OtpravnikNew')))
export const OtpravnikEditPage = Loadable(lazy(() => import('../pages/Optravnik/OtpravnikEditPage')))
export const PublisherPage = Loadable(lazy(() => import('../pages/Optravnik/Publisher')))
export const OtpravnikNewBIS = Loadable(lazy(() => import('../pages/Optravnik/AddNewBisVehicle')))
export const OtherOtpravnikPage = Loadable(lazy(() => import('../pages/Optravnik/Other')))

// SCHEDULED DEP AND ARR
export const ScheduledDepAndArrList = Loadable(lazy(() => import('../pages/ScheduledDepAndArr')))
export const PeronizationList = Loadable(lazy(() => import('../pages/Peronization')))
export const PeronizationNew = Loadable(lazy(() => import('../pages/Peronization/PeronizationNew')))
export const PeronizationPrice = Loadable(lazy(() => import('../pages/Peronization/PeronizationPrice')))
export const ParkingList = Loadable(lazy(() => import('../pages/Parking')))

// CARRIER REPORT
export const CarrierReport = Loadable(lazy(() => import('../pages/CarrierReport')))
export const CarrierReportEdit = Loadable(lazy(() => import('../pages/CarrierReport/CarrierReportCustomTable')))
export const CarrierReportDetailed = Loadable(lazy(() => import('../pages/CarrierReport/CarrierReportDetailed')))
export const CarrierReportPrivilegesTickets = Loadable(lazy(() => import('../pages/CarrierReport/CarrierReportPrivilegesTickets')))
export const CarrierReportReturnTickets = Loadable(lazy(() => import('../pages/CarrierReport/CarrierReportReturnTickets')))
export const CarrierReportTickets = Loadable(lazy(() => import('../pages/CarrierReport/CarrierReportTickets')))
export const ValidatedTicketsPage = Loadable(lazy(() => import('../pages/CarrierReport/validatedTickets')))
export const AllTicketsPage = Loadable(lazy(() => import('../pages/CarrierReport/AllTickets')))

// OPERATORS
export const OperatorsList = Loadable(lazy(() => import("../pages/Operators")));
export const OperatorsNew = Loadable(lazy(() => import("../pages/Operators/OperatorsNew")));
export const OperatorsEditPage = Loadable(lazy(() => import("../pages/Operators/OperatorsEditPage")));

// COMMENTS
export const CommentsList = Loadable(lazy(() => import("../pages/Comments")));
export const CommentsNew = Loadable(lazy(() => import("../pages/Comments/CommentsNew")));
export const CommentsEditPage = Loadable(lazy(() => import("../pages/Comments/CommentsEditPage")));

// COMMISION CALCULATION
export const CommissionCalculation = Loadable(lazy(() => import('../pages/CommissionCalculation')))
export const CalculationOfIncomeForStationPage = Loadable(lazy(() => import("../pages/CalculationOfIncomeForStation")));

// SETTINGS
export const SettingsPage = Loadable(lazy(() => import("../pages/Settings")));

// PREVIEW

export const PriceListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewPriceList")));
export const LinesListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewLinesList")));
export const DeparturesListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewDeparturesList")));
export const PreviewTimes = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewTimes")));
export const DiscountsListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewDiscountsList")));
export const DriveParkListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewDriveParkList")));
export const CommentsListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewCommentsList")));
export const AllotmentListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewAllotmentList")));
export const CurrentAllotmentListPreviewPage = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewCurrentAllotments")));
export const PreviewDeparturesLinked = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewDeparturesLinked")));
export const PreviewCancelledTripsHistory = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewCancelledTripsHistory")));
export const PreviewRatesHistory = Loadable(lazy(() => import("../pages/OperatorPreview/PreviewRatesHistory")));
export const DeparturesStatus = Loadable(lazy(() => import("../pages/DepartureStatus")));

//ADMIN
export const UserPathPrivilegesPage = Loadable(lazy(() => import("../pages/Admin/UserPathPrivileges")));


//ADMIN
export const BarriersPage = Loadable(lazy(() => import("../pages/Barriers")));
export const BarriersExternalPartnersPage = Loadable(lazy(() => import("../pages/Barriers/ExternalPartners")));

