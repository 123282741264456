import React, {useEffect, useState} from 'react';
import {CircularProgress, Divider, Skeleton, Stack, Typography} from '@mui/material';
import {useLocales} from "../../../locales";
import {getBusByIdApi} from "../../../api/vehicle";
import moment from "moment";
import {DIRECTION_NAME} from "../../../constants";
import {dispatch} from "../../../redux/store";
import {createReservation} from "../../../redux/slices/reservations";
import {getCashierName} from "../../../utils/other";
import {postTicketReleaseAPI} from "../../../api/tickets";
import {useLoading} from "../../../context/Loading";

// ** KADA SE KERIRA REZERVACIJA BIRA SE N SEDIŠTA ** //
export default function SeatsLayout({
                                        isReservation,
                                        setValue,
                                        getValues,
                                        passIndex,
                                        passengers,
                                        multiple = false,
                                        seats = [],
                                        loading = true,
                                        showLegend = true,
                                        onSelectSeat,
                                        wrapperSx,
                                        directionType,
                                        arrStationId,
                                        depStationId
                                    }) {
    const {startLoading, endLoading} = useLoading();
    const prevSelectedSeats = Array.isArray(passengers)
        ? passengers.filter(f => f.seatNumber !== '').map(f => ({
            seat: f.seatNumber,
            vehicleId: f.vehicleId
        }))
        : [];

    const [selectedSeat, setSelectedSeat] = useState(prevSelectedSeats || []);
    const {translate} = useLocales();

    if (loading) {
        return <Skeleton variant="rectangular" width="100%" height={200}/>;
    }

    const vehicles = new Set(seats.map(seat => seat.vehicleId));
    const vehicleSeatsMap = Array.from(vehicles).reduce((acc, vehicleId) => {
        const vehicleSeats = seats.filter(seat => seat.vehicleId === vehicleId);

        const hasValidAlId = vehicleSeats.some(seat => seat.alId !== null);

        if (hasValidAlId) {
            acc[vehicleId] = vehicleSeats;
        }

        return acc;
    }, {});

    const handleSeatClick = (seat) => {
        if (!isReservation) {
            const arr = [...passengers]
            const obj = {...arr[passIndex]}
            if (obj.seatNumber && seat.seat !== obj.seatNumber) {
                startLoading()
                const curTicketID = getValues(`passengers.${passIndex}.id`)
                dispatch(createReservation({
                    isReturnTicket: directionType === DIRECTION_NAME[1],
                    journeyDate: seat?.journeyDate ? moment.utc(seat?.journeyDate).format("yyyy-MM-DD HH:mm:ss") : null,
                    reservedTime: moment().utc().format("yyyy-MM-DD"),
                    tripId: seat.tripId,
                    arrStationId: arrStationId,
                    depStationId: depStationId,
                    cashier: getCashierName(),
                    passengers: [obj],
                    isTemporary: true
                })).unwrap().then((response) => {
                    setValue(`passengers.${passIndex}.id`, response[0].id)
                    postTicketReleaseAPI([curTicketID])
                        .then(r => {
                            console.log("Response:", r);
                        })
                        .catch(er => {
                            console.error("Error:", er);
                        });
                }).catch(e => {

                }).finally(() => {
                    endLoading();
                })
            } else {
                obj.seat = seat.seat
                obj.vehicleId = seat.vehicleId
                if (seat) {
                    startLoading()
                    dispatch(createReservation({
                        isReturnTicket: directionType === DIRECTION_NAME[1],
                        journeyDate: seat?.journeyDate ? moment.utc(seat?.journeyDate).format("yyyy-MM-DD HH:mm:ss") : null,
                        reservedTime: moment().utc().format("yyyy-MM-DD"),
                        tripId: seat.tripId,
                        arrStationId: arrStationId,
                        depStationId: depStationId,
                        cashier: getCashierName(),
                        passengers: [obj],
                        isTemporary: true
                    })).unwrap().then((response) => {
                        setValue(`passengers.${passIndex}.id`, response[0].id)
                    }).catch(e => {

                    }).finally(() => {
                        endLoading();
                    })
                }
            }
        }

        if (multiple) {
            const arr = [...selectedSeat];
            const index = arr.findIndex(i => i.seat === seat.seat);
            if (index === -1) {
                arr.push(seat);
            } else {
                arr.splice(index, 1);
            }
            setSelectedSeat(arr);
            if (onSelectSeat) {
                onSelectSeat(arr);
            }
        } else {
            if (selectedSeat?.vehicleId === seat?.vehicleId && selectedSeat?.seat === seat?.seat) {
                seat = null;
            }
            if (onSelectSeat) {
                onSelectSeat(seat);
            }
        }
    };

    return (
        <Stack display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1.2}
               sx={wrapperSx}>
            {showLegend && (
                <Stack direction="row" spacing={1}>
                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Seat seat="" free={true} isLegend={true}/>
                        <Typography variant="caption" component="div">
                            {translate("free")}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" variant="middle" flexItem/>
                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Seat seat="" reserved={true} isLegend={true}/>
                        <Typography variant="caption" component="div">
                            {translate("reserved")}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" variant="middle" flexItem/>
                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Seat seat="" occupied={true} isLegend={true}/>
                        <Typography variant="caption" component="div">
                            {translate("occupied")}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" variant="middle" flexItem/>
                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Seat seat="" selected={true} isLegend={true}/>
                        <Typography variant="caption" component="div">
                            {translate("selected")}
                        </Typography>
                    </Stack>
                </Stack>
            )}
            <Stack direction={multiple ? "row" : "row"} spacing={1.2}>
                {selectedSeat && selectedSeat?.map(item => (
                    <Stack direction="row" spacing={1.2} key={item.seat}>
                        <Plate vehicleId={item.vehicleId}/>
                        <Typography> - {item.seat}</Typography>
                    </Stack>
                ))
                }
            </Stack>
            {Object.entries(vehicleSeatsMap).map(([vehicleId, vehicleSeats]) => {
                const rows = [[], [], [], []];


                vehicleSeats.forEach((seat, index) => {
                    const rowIndex = index % 4;
                    rows[rowIndex].push(seat);
                });


                const maxRowLength = Math.max(...rows.map(row => row.length));


                rows.forEach(row => {
                    while (row.length < maxRowLength) {
                        row.push({emptyRow: true});
                    }
                });

                return (
                    <Stack
                        display="flex" flexDirection="column-reverse"
                        sx={{
                            borderTopLeftRadius: 25,
                            borderBottomLeftRadius: 25,
                            paddingLeft: 4,
                            borderWidth: 1,
                            border: "solid",
                            overflowX: "auto"
                        }}
                        key={vehicleId}
                    >
                        {rows.map((row, rowIndex) => (
                            <React.Fragment key={rowIndex}>
                                <Stack
                                    sx={{width: "100%"}}
                                    display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start"
                                >
                                    {row.map((value, seatIndex) => (
                                        <Seat
                                            alId={value?.alId}
                                            onClick={() => handleSeatClick(value)}
                                            selected={isSeatSelected(selectedSeat, value)}
                                            {...value}
                                            key={seatIndex}
                                        />
                                    ))}
                                </Stack>


                                {rowIndex === 1 && (
                                    <Stack sx={{height: "20px"}}/>
                                )}
                            </React.Fragment>
                        ))}
                    </Stack>
                );
            })}


        </Stack>
    );
}

// GLEDAMO KOJA SU SVE IZABRANA
const isSeatSelected = (selectedSeats, seat) => {
    if (Array.isArray(selectedSeats)) {
        return selectedSeats.some(lSeat => lSeat.vehicleId === seat.vehicleId && lSeat.seat === seat.seat);
    }
    return selectedSeats?.vehicleId === seat?.vehicleId && selectedSeats?.seat === seat?.seat;
};


const Seat = ({
                  alId, seat, reserved, free, occupied, emptyRow = false, selected = false,
                  onClick, isLegend, reservationExpireSec
              }) => {
    const [timeLeft, setTimeLeft] = useState();

    useEffect(() => {
        if (reservationExpireSec < 600) {
            setTimeLeft(reservationExpireSec);
        }
    }, [reservationExpireSec]);

    useEffect(() => {
        if (timeLeft === undefined || timeLeft <= 0) return;

        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                const newTime = prevTime !== undefined ? prevTime - 1 : 0;
                return Math.max(newTime, 0);
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    return (
        <Stack
            direction="column"
            onClick={(alId && !reserved && !occupied) ? onClick : undefined}
            sx={{
                userSelect: "none",
                width: emptyRow ? 10 : (isLegend ? 28 : 60),
                minWidth: emptyRow ? 10 : (isLegend ? 28 : 60),
                height: emptyRow ? 20 : (isLegend ? 25 : 55),
                minHeight: emptyRow ? 20 : (isLegend ? 25 : 55),
                borderTopLeftRadius: 7,
                borderBottomLeftRadius: 7,
                borderRight: emptyRow ? 0 : 8,
                borderWidth: emptyRow ? 0 : 8,
                fontSize: 15,
                backgroundColor: getBgColor(seat, reserved, occupied, emptyRow, selected, alId, free),
                margin: 0.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: (emptyRow || !alId || reserved || occupied) ? "not-allowed;" : "pointer",
                // '&:hover': {
                //     backgroundColor: alId ? getBgColor(reserved, occupied, emptyRow, selected, alId, free) : undefined,
                // },
            }}>
            <span>
                {emptyRow ? "" : seat}
            </span>
            <span>
                {emptyRow ? "" : (reservationExpireSec && reservationExpireSec < 600 ? formatTime(timeLeft) : "")}
            </span>
        </Stack>
    );
}

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const getBgColor = (seat, reserved, occupied, emptyRow, selected, alId, free) => {
    if (reserved) {
        return "warning.main";
    }
    if (emptyRow) {
        return "transparent";
    }
    if (selected) {
        return "yellow"
    }
    if (occupied) {
        return "error.main";
    }
    if (alId || free) {
        return "success.main"
    }
    return "lightgrey";
}

const Plate = ({vehicleId}) => {
    const [loading, setLoading] = useState(true);
    const [vehicle, setVehicle] = useState();
    useEffect(() => {
        if (vehicleId) {
            getBusByIdApi(vehicleId).then(({data}) => {
                if (data) {
                    setVehicle(data)
                }
            }).catch(e => {
                console.error(e, "getBusByIdApi")
            }).finally(() => {
                setLoading(false)
            })
        }

    }, []);

    if (loading) {
        return <CircularProgress size={25}/>
    }
    return <Typography>{vehicle?.plateNumber}</Typography>
}

// PROVERAVA DA LI BAR IMA JEDNO MESTO DA BI PRIKAZAO TAJ AUTOBUS
function hasAvailableSeat(vehicleSeats) {
    return vehicleSeats.some(seat => seat.alId !== null && !seat.occupied && !seat.reserved);
}
