import {Button, Card, Divider, Stack, TextField, Tooltip, Typography} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Image from "../../components/image";
import Iconify from "../../components/Iconify";
import {fCurrency} from "../../utils/formatNumber";
import {AnimatePresence, motion} from "framer-motion";
import {
    COUNTRY_BORDERS,
    DIRECTION_NAME,
    INVOICE_TYPE,
    OPERATORS_GROUP,
    PAYMENT_TYPE,
    ROLES_CAN_ISSUE_TICKETS,
    SERBIA_ALPHA_2_CODE
} from "../../constants";
import Passenger from "./InvoiceForm/Passenger";
import Services from "./InvoiceForm/Services";
import AmountDetails from "./InvoiceForm/AmountDetails";
import B2B from "./B2B";
import {LoadingButton} from "@mui/lab";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {getSessionOperator, getSessionUser} from "../../helper/session";
import {useSnackbar} from "notistack";
import * as React from "react";
import {useEffect, useLayoutEffect, useState} from "react";
import moment from "moment";
import {getCommentsAPI, getitinerariesAPI} from "../../api/trip";
import Comments from "./Comments";
import {getTrafficType} from "../../helper/homePage";
import {DatePicker} from "@mui/x-date-pickers";
import {useLocales} from "../../locales";
import VerificationDialog from "./VerificationDialog";
import ItinererDialog from "./ItinererDialog";
import PlatformDialog from "./PlatformDialog";
import ReturnTripDialog from "./ReturnTripDialog";
import ReturnTripCard from "./ReturnTripCard";
import {RHFTextField} from "../../components/hook-form";
import {CalendarIconSvg} from "../../constants/icons";
import {useSelector} from "../../redux/store";
import InfoDialog from "./InfoDialog";
import {getCommentAPI} from "../../api/lines";
import PriceListDialog from "../Departures/Dialog/PriceListDialog";
import {isWithinTwoHours} from "../../helper/timeCheck";
import {invoiceTotalAmount} from "../../helper/invoice/allCalculations";
import {postTicketReleaseAPI} from "../../api/tickets";

export default function Results({
                                    hoveredRef,
                                    index,
                                    onSuccess,
                                    data,
                                    directionType,
                                    handleSubmit,
                                    onSubmit,
                                    handleOpenPaymentDialog
                                }) {
    const {homeArrStations} = useSelector(state => state.stations);
    const currentTime = moment.tz("Europe/Belgrade").format("YYYY-MM-DD HH:mm:ss");
    const OPERATOR = getSessionOperator()
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const [showVerificationDialog, setShowVerificationDialog] = useState({
        open: false,
        data: null
    })
    const [departureTimeFromSpot, setDepartureTimeFromSpot] = useState()
    const [lineComment, setLineComment] = useState()
    const [loadingItinerants, setLoadingItinerants] = useState(false)
    const [showItinerer, setShowItinerer] = useState({open: false, data: null})
    const {
        setValue,
        control,
        getValues
    } = useFormContext();
    const [commentsProps, setCommentsProps] = useState({
        loading: false,
        comments: []
    })
    const isDesktop = useResponsive("up", "lg");

    const flagArr = findFlag(data?.arrCountryName)
    const flagDep = findFlag(data?.depCountryName)

    useLayoutEffect(() => {
        setCommentsProps(prevState => ({
            ...prevState,
            loading: true
        }))

        getCommentsAPI(data.tripId, moment.utc(data.departureTripTime).format("YYYY-MM-DD")).then(({data}) => {
            setCommentsProps(prevState => ({
                ...prevState,
                comments: data
            }))
        }).catch(reason => {
            console.error("getCommentsAPI", reason);
            // enqueueSnackbar("Nije moguće učitati komentare", {variant: "error"})
        }).finally(() => {
            setCommentsProps(prevState => ({
                ...prevState,
                loading: false
            }))
        })
    }, []);


    const isOpenIndex = useWatch({
        control,
        name: 'isOpenIndex'
    })

    const hovered = useWatch({
        control,
        name: 'hovered'
    })

    const dateFrom = useWatch({
        control,
        name: 'dateFrom'
    })
    const dateTo = useWatch({
        control,
        name: 'dateTo'
    })

    const actions = useWatch({
        control,
        name: 'actions'
    })

    const showPlatformDialog = useWatch({
        control, name: "showPlatformDialog"
    })

    const showInfoDialog = useWatch({
        control, name: "showInfoDialog"
    })

    const showPriceListDialog = useWatch({
        control, name: "showPriceListDialog"
    })

    const showReturnDialog = useWatch({
        control, name: "showReturnDialog"
    })

    const returnTrip = useWatch({
        control, name: "returnTrip"
    })

    useEffect(() => {
        if (isOpenIndex !== -1) {
            const lineID = data.lineId
            getitinerariesAPI(data.tripId, getValues("dateFrom")).then(({data}) => {
                const foundTime = findStationAndCalculateTimes(data)
                if (foundTime) {
                    setDepartureTimeFromSpot(foundTime)
                } else {
                    if (departureTimeFromSpot) {
                        setDepartureTimeFromSpot()
                    }
                }
                getCommentAPI(lineID).then(response => {
                    setLineComment(response?.data || null)
                })
            }).catch(e => {
                console.error(e, "Error fetching Line Stations");
            });
        }
    }, [isOpenIndex]);

    const handleKeyboardPress = e => {
        if (e.key === 'F6') {
            e.preventDefault();
            const flag = isWithinTwoHours(data.departureTripTime);
            if (flag) {
                return enqueueSnackbar("Nije moguće uraditi rezervaciju 2h pred put!", {variant: "warning"})
            }
            if (getValues('isOpenIndex') === index) {
                setValue("showReservations", true)
                setValue("reservationData", data)
            }
        }
        if (e.altKey && e.key.toLowerCase() === 'g') {
            if (data?.canceled || !moment.utc(data?.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
            }
            e.preventDefault();
            validateAndSubmit(data, handleSubmit((allProps) => onSubmit(allProps, PAYMENT_TYPE.cash, INVOICE_TYPE.normal)));
        }

        if (e.altKey && e.key.toLowerCase() === 'k') {
            if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
            }
            e.preventDefault();
            validateAndSubmit(data, handleSubmit((allProps) => onSubmit(allProps, PAYMENT_TYPE.card, INVOICE_TYPE.normal)));
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardPress);
        return () => {
            window.removeEventListener('keydown', handleKeyboardPress)
        }

    }, [])

    useEffect(() => {
        if (isOpenIndex !== -1) {

            window.scrollTo({top: 0, behavior: 'smooth'});


            setTimeout(() => {
                window.scrollBy({top: 205, behavior: 'smooth'});
            }, 300);
        }
    }, [isOpenIndex]);


    const trafficType = getTrafficType();
    const activeAction = actions?.find(action => action?.trafficType === trafficType?.id);

    const validateAndSubmit = (row, func) => {
        if (row.requiresReturnDate === true && dateTo === null && directionType === DIRECTION_NAME[1]) {
            return enqueueSnackbar("Izaberite datum povratka", {variant: "warning"});
        }
        func();
    }

    const handleCloseVerificationDialog = () => {
        setShowVerificationDialog({
            open: false,
            data: null
        })
    }

    const handleCloseItinererDialog = () => {
        setShowItinerer({open: false, data: null})
    }

    const handleClosePlatformDialog = () => {
        setValue("showPlatformDialog", false)
    }

    const handleRemoveReturnTrip = () => {
        setValue("showReturnDialog", false)
        setValue("returnTrip", null);
        setValue('isOpenIndex', -1);
        setValue('invoiceLine', null)
    }

    const passengers = useWatch({
        control,
        name: 'passengers'
    })

    const defaultServices = useWatch({
        control,
        name: 'defaultServices'
    })

    const {currentTaxRates} = useSelector(((state) => state.taxCore));
    const services = useWatch({
        control,
        name: 'services'
    })

    const filteredOnlyForSale = defaultServices.filter(item => item.includeOnIssuingTicket === true && item.inactive === false)

    const calculated = invoiceTotalAmount({
        price: data.priceOneWay,
        returnPrice: data.priceReturnTicket,
        priceMonthly: data.priceMonthly,
        passengers: passengers,
        saleAction: actions[0], // ovo treba da bude objekat jer samo jedna moze biti
        services: services?.length > 0 ? services : [filteredOnlyForSale],
        directionType: directionType,
        currentTaxRates: currentTaxRates,
        externalSale: data.externalSale
    })

    const doCancelation = () => {
        const currentTicketIDS = passengers
            ?.filter(p => p?.id != null)  // Izvlačenje ID-ova karata za otkazivanje
            .map(p => p.id);
        if (currentTicketIDS?.length > 0) {
            // Ako postoje karte za otkazivanje
             postTicketReleaseAPI(currentTicketIDS);
        }
    }

    const isSoldOut = data.freeSeats === 0;

    const handleCardClick = () => {
        if (!isSoldOut) {
            {
                doCancelation()
                setValue("passengersCount", 1)
                setValue("passengers", [{
                    havePrivilege: false,
                    discountPCT: null,
                    type: "adult",
                    priceException: null,
                    email: "",
                    insurance: false,
                    name: "",
                    phone: "",
                    sms: "",
                    surname: "",
                    clientIdentity: ""
                }])
                if (directionType === DIRECTION_NAME[1] && data.externalSale === true && !returnTrip) {
                    setValue("showReturnDialog", true);
                    setValue('isOpenIndex', index);
                    setValue('invoiceLine', data)
                    return;
                }
                if (!ROLES_CAN_ISSUE_TICKETS.includes(OPERATOR.group)) {
                    setLoadingItinerants(true)
                    getitinerariesAPI(data.tripId, getValues("dateFrom")).then(({data}) => {
                        setShowItinerer({open: true, data: data})
                    }).catch(reason => {
                        console.error("getitinerariesAPI", reason)
                        enqueueSnackbar(translate("error"), {variant: "error"})
                    }).finally(() => {
                        setLoadingItinerants(false)
                    })
                    return;
                }

                if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                    return
                }
                if (
                    (data?.arrCountryCode !== SERBIA_ALPHA_2_CODE || data?.depCountryCode !== SERBIA_ALPHA_2_CODE)
                    && (data?.depBorderDistance <= 0 ||
                        data?.arrBorderDistance <= 0)) {
                    enqueueSnackbar("Molimo Vas podesite udaljenost stanice od graničnog prelaza", {variant: "error"})
                } else {
                    if (isOpenIndex !== index) {
                        if (isOpenIndex !== -1) {
                            setValue('isOpenIndex', -1);
                        } else {
                            setValue('isOpenIndex', index);
                        }

                        const foundStation = homeArrStations.find(f => f.id === data?.arrStationId)
                        if(foundStation) {
                            setValue('endStation', foundStation)

                        }
                        setValue('invoiceLine', data)
                        if (activeAction?.amount > 0) {
                            setValue('discountPCT', {
                                name: activeAction?.salesActionType?.name,
                                discountPCT: activeAction?.amount
                            })
                        }
                    } else {
                        setValue("returnTrip", null)
                        setValue('isOpenIndex', -1);
                    }
                }
            }
        }
    };

    // TODO dodati proveru da li postoji povratak?
    return (
        <div style={{position: "relative"}}>
            {activeAction &&
                <Tooltip title={activeAction?.salesActionType?.name || ""}>
                    <Iconify
                        width={30}
                        icon="mdi:discount"
                        sx={{
                            zIndex: 999,
                            top: -10,
                            left: -10,
                            position: "absolute",
                            color: "primary.main"
                        }}/>
                </Tooltip>
            }
            <Stack
                ref={hovered === index ? hoveredRef : null}
                onClick={handleCardClick}
                direction="row"
                sx={{
                    border: hovered === index ? '2px dashed #6DABE4' : 'none',
                    borderRadius: 2,
                    mt: 2,
                    pointerEvents: isSoldOut ? 'none' : 'auto',
                    opacity: isSoldOut ? 0.5 : 1,
                    position: 'relative'
            }}>
                <Card sx={{
                    border: data.canceled ? "2px dashed red" : null,
                    p: 2,
                    width: '80%',
                    "&:hover": {
                        cursor: loadingItinerants || isSoldOut ? "not-allowed" : "pointer",
                        background: "#f0f1f2",
                    }
                }}>
                    {isSoldOut && (
                        <Typography
                            variant="h3"
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                letterSpacing: 1.2,
                                transform: "translate(-50%, -50%) rotate(-20deg)",
                                color: "rgba(255, 0, 0, 0.8)",
                                fontWeight: 700,
                                zIndex: 100
                            }}
                        >
                            Rasprodato
                        </Typography>
                    )}
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="h3"
                                        sx={{
                                            fontWeight: 700,
                                            color: "#6DABE4"
                                        }}>{moment.utc(data.departureTripTime).format("HH:mm")}</Typography>
                            <Typography sx={{fontSize: 16, letterSpacing: 1.2}}
                                        variant="subtitle1">{data.depStationName}</Typography>
                            <Typography sx={{letterSpacing: 1.2}}
                                        variant="caption">({data.depCountryName})</Typography>
                        </Stack>
                        <Image src="/assets/icons/home/bus-outbound.svg"
                               disabledEffect
                               alt="pin"
                               sx={{height: 64}}
                        />
                        <Stack direction="column" alignItems="center">
                            <Typography variant="subtitle1" sx={{
                                fontSize: 18,
                                color: "text.disabled"
                            }}>{data?.depStationName?.toUpperCase()?.slice(0, 3)}</Typography>
                            <Divider orientation="vertical" sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>

                            {flagDep !== "" ?
                                <Image src={flagDep}
                                       disabledEffect
                                       alt="pin"
                                       sx={{height: 30}}
                                />
                                :
                                <Typography variant="subtitle2"
                                            sx={{color: "text.disabled"}}>{findFlag(data?.depCountryName)}</Typography>
                            }
                        </Stack>
                        <Stack sx={{display: 'flex', flex: 1}}>
                            {data?.issuedFor?.name?.length > 0 ?
                                <Stack spacing={1}>
                                    <Typography variant="h6"
                                                sx={{letterSpacing: 1.2, textAlign: 'center', color: 'success.main'}}>
                                        {data?.issuedFor?.name?.toUpperCase()}
                                    </Typography>
                                    {/*<Typography variant="subtitle2"*/}
                                    {/*            sx={{letterSpacing: 1.4, textAlign: 'center', color: 'success.main'}}>*/}
                                    {/*    (PIB: {data?.issuedFor?.tin})*/}
                                    {/*</Typography>*/}
                                </Stack>
                                :
                                <Typography variant="h6"
                                            sx={{letterSpacing: 1.2, textAlign: 'center', color: 'success.main'}}>
                                    {getSessionUser()?.name?.toUpperCase()}
                                </Typography>
                            }
                            <Typography variant="subtitle2"
                                        sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                PREVOZNIK: {data?.operatorName?.toUpperCase()}
                            </Typography>
                            <Divider textAlign="center">
                                <Typography variant="subtitle2"
                                            sx={{color: "text.disabled"}}>{data?.tripTime}</Typography>
                            </Divider>

                            <Typography variant="subtitle2"
                                        sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                LINIJA: {data?.printText?.toUpperCase()}
                            </Typography>
                        </Stack>

                        <Stack direction="column" alignItems="center">
                            <Typography variant="subtitle1"
                                        sx={{color: "text.disabled"}}>{data.arrStationName.slice(0, 3).toUpperCase()}</Typography>
                            <Divider orientation="vertical" sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>
                            {flagArr !== "" ?
                                <Image src={flagArr}
                                       disabledEffect
                                       alt="pin"
                                       sx={{height: 30}}
                                />
                                :
                                <Typography variant="subtitle2"
                                            sx={{color: "text.disabled"}}>{findFlag(data?.arrCountryName)}</Typography>
                            }
                        </Stack>
                        <Image src="/assets/icons/home/bus-inbound.svg"
                               disabledEffect
                               alt="pin"
                               sx={{height: 64}}
                        />
                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="h3"
                                        sx={{
                                            fontWeight: 700,
                                            color: "#6DABE4"
                                        }}>{moment(data?.arrTripTime).format("HH:mm")}</Typography>
                            <Typography sx={{fontSize: 16, letterSpacing: 1.2}}
                                        variant="subtitle1">{data?.arrStationName}</Typography>
                            <Typography sx={{letterSpacing: 1.2}}
                                        variant="caption">({data.arrCountryName})</Typography>
                        </Stack>
                    </Stack>
                    {data.canceled &&
                        <Typography textAlign="center" variant="subtitle2"
                                    color="darkRed">({data.canceledNote})</Typography>}
                    <Comments routeComment={data.routeComment} comments={commentsProps.comments} allowedViewers={data.allowedViewers}
                              loading={commentsProps.loading}/>
                </Card>
                <Divider
                    orientation={isDesktop ? 'vertical' : 'horizontal'}
                    flexItem
                    sx={{borderRightWidth: 4, borderStyle: 'dashed'}}
                />
                <Card sx={{
                    border: data.canceled ? "2px dashed red" : null,
                    p: 2, width: '20%',
                    // backgroundColor: `${isTimeTenMinutesBefore(data.departureTripTime) ? "#ffe3e3" : "#fff"}`,
                    "&:hover": {
                        cursor: "pointer",
                        background: "#f0f1f2",
                    }
                }}>
                    <Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="subtitle2" sx={{fontWeight: 500, color: "text.disabled"}}>
                                Broj vozila:
                            </Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <Typography variant="subtitle2"
                                            sx={{
                                                fontWeight: 500,
                                                color: "text.disabled"
                                            }}>{data?.numberOfVehicles}</Typography>
                                <Iconify sx={{color: '#6DABE4'}} icon="mingcute:bus-line" width={16}/>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="subtitle2" sx={{fontWeight: 500, color: "text.disabled"}}>
                                Broj mesta:
                            </Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <Typography variant="subtitle2"
                                            sx={{
                                                fontWeight: 500,
                                                color: "text.disabled"
                                            }}>{data?.freeSeats}</Typography>
                                <Iconify sx={{color: '#6DABE4'}} icon="ic:round-event-seat" width={18}/>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="subtitle2" sx={{fontWeight: 500, color: "text.disabled"}}>
                                Broj rezervacija:
                            </Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <Typography variant="subtitle2"
                                            sx={{
                                                fontWeight: 500,
                                                color: "text.disabled"
                                            }}>
                                    {data?.seatsReserved}
                                    {/*<SeatOccupation*/}
                                    {/*    tripId={data.tripId}*/}
                                    {/*    journeyDate={moment(getValues("dateFrom")).format("yyyy-MM-DD")}*/}
                                    {/*    fromStationId={data.depStationId}*/}
                                    {/*    toStationId={data.arrStationId}*/}
                                    {/*    freeSeats={data?.freeSeats || 0}*/}
                                    {/*/>*/}
                                </Typography>
                                <Iconify sx={{color: '#6DABE4'}} icon="ic:round-event-seat" width={18}/>
                            </Stack>
                        </Stack>
                        <Stack alignItems="center" direction="row" justifyContent="space-between">
                            <Typography variant="subtitle2" sx={{fontWeight: 500, color: "text.disabled"}}>
                                Broj perona:
                            </Typography>

                        </Stack>
                        {/*<Stack alignItems="center" direction="row" justifyContent="space-between">*/}
                        {/*    <Typography variant="subtitle2" sx={{fontWeight: 500, color: "text.disabled"}}>*/}
                        {/*        Trajanje u min:*/}
                        {/*    </Typography>*/}
                        {/*    <Stack direction="row" spacing={1}>*/}
                        {/*        <Typography variant="subtitle2"*/}
                        {/*                    sx={{fontWeight: 500, color: "text.disabled"}}>*/}
                        {/*            {data?.timeTravelInMinutes}</Typography>*/}
                        {/*        <Iconify sx={{color: '#6DABE4'}} icon="fa-solid:route" width={16}/>*/}
                        {/*    </Stack>*/}
                        {/*</Stack>*/}
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                            color: "text.disabled"
                                        }}>
                                {data?.platformNumber || "/"}
                            </Typography>
                            <Typography variant="h5" sx={{textAlign: 'right', color: 'success.main', mt: 1}}>
                                {(directionType === DIRECTION_NAME[1] && data.externalSale === true && !returnTrip ?
                                    translate("chooseReturnTrip") :
                                    `${fCurrency(calculated.unitPrice.toString() || "0")} din`)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
            <AnimatePresence>
                {(isOpenIndex !== -1 &&
                    <motion.div
                        key="card"
                        initial={{opacity: 0, y: -20, height: 0}}
                        animate={{opacity: 1, y: 0, height: "auto"}}
                        exit={{opacity: 0, y: -20, height: 0}}
                        transition={{duration: 0.3}}
                        sx={{marginTop: 16}}>
                        {returnTrip &&
                            <ReturnTripCard
                                trip={returnTrip}
                                onClose={handleRemoveReturnTrip}
                            />}
                        <Card sx={{p: 2, mt: 2}}>
                            <Stack direction="row"
                                   alignItems="center"
                                   justifyContent="space-between">
                                <Stack direction="row" gap={2}>
                                    <Button
                                        sx={{width: 170}}
                                        color="primary"
                                        disabled={data.externalSale || isWithinTwoHours(data.departureTripTime)}
                                        variant="outlined"
                                        onClick={() => {
                                            if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                                return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                            }
                                            setValue("showReservations", true)
                                            setValue("reservationData", data)
                                        }}>
                                        Rezervacija [F6]
                                    </Button>
                                    {directionType === DIRECTION_NAME[0] &&
                                        <Button
                                            sx={{width: 170}}
                                            disabled={data.externalSale}
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => {
                                                if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                                    return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                                }
                                                setShowVerificationDialog({
                                                    open: true,
                                                    data: data
                                                })
                                            }}>
                                            Overa [F4]
                                        </Button>
                                    }
                                    <Button
                                        sx={{width: 170}}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                                return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                            }
                                            setValue("showPlatformDialog", true)
                                        }}>
                                        Peron
                                    </Button>
                                    <Button
                                        sx={{width: 170}}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            if(getValues("startStation")?.id &&  getValues("endStation")){
                                                setValue("showInfoDialog", {
                                                    open: true,
                                                    data: {
                                                        operatorId: data?.operatorId,
                                                        depStationId: getValues("startStation")?.id,
                                                        arrStationId: getValues("endStation").id,
                                                        dateFrom: moment(getValues("dateFrom")).format("YYYY-MM-DD"),
                                                        toLocationId: null,
                                                        isIndividual: true,
                                                    }
                                                })
                                            } else {
                                                enqueueSnackbar("Morate izabrati stanicu do", {variant: "info"})
                                            }
                                        }}>
                                        Povratak iz mesta
                                    </Button>
                                    <Button
                                        sx={{width: 170}}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            setValue("showPriceListDialog", {
                                                open: true,
                                                lineId: data.lineId,
                                                rateCardId: data.rateCardId,
                                                tripId: data.tripId,
                                                operatorName: data?.operatorName,
                                                lineName: data.printText,
                                                operatorId: data.operatorId,
                                                editable: false,
                                                arrStationId: data.arrStationId
                                            })
                                        }}>
                                        Cenovnik
                                    </Button>
                                    <LoadingButton
                                        loading={loadingItinerants}
                                        sx={{width: 170}}
                                        disabled={data.externalSale}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            setLoadingItinerants(true)
                                            getitinerariesAPI(data.tripId, getValues("dateFrom")).then(({data}) => {
                                                setShowItinerer({open: true, data: data})
                                            }).catch(reason => {
                                                console.error("getitinerariesAPI", reason)
                                                enqueueSnackbar(translate("error"), {variant: "error"})
                                            }).finally(() => {
                                                setLoadingItinerants(false)
                                            })
                                        }}>
                                        Itinerer
                                    </LoadingButton>
                                </Stack>
                                {data.requiresReturnDate && directionType === DIRECTION_NAME[1] && !returnTrip &&
                                    <Controller
                                        name="dateTo"
                                        control={control}
                                        render={({field, fieldState: {error}}) => (<DatePicker
                                            {...field}
                                            minDate={dateFrom}
                                            onChange={(newValue) => {
                                                setValue('dateTo', newValue)
                                            }}
                                            label={translate("returnDate")}
                                            inputFormat="dd/MM/yyyy"
                                            slotProps={{textField: {size: 'small'}}}
                                            slots={{openPickerIcon: CalendarIconSvg}}
                                            renderInput={(params) => (<TextField
                                                {...params}
                                                error={!!error}
                                                helperText={error?.message || ''}
                                            />)}/>)}
                                    />
                                }
                                <B2B/>
                            </Stack>

                            {departureTimeFromSpot &&
                                <Typography sx={{mt: 1}} variant="h6" color="text.disabled">Stvarni
                                    polazak: {departureTimeFromSpot.realDepTime} - Dolazak u
                                    NS: {departureTimeFromSpot.arrTime}</Typography>}
                            {lineComment &&
                                <Typography sx={{mt: 1}} variant="h6" color="text.disabled">Komentar
                                    linije: {lineComment}</Typography>}
                            <AmountDetails
                                state={data}
                                directionType={directionType}
                                total={0}
                                discount={0}/>

                            <Stack sx={{mb: 1, mt: 1}} direction="row" spacing={1.2} alignItems="center"
                                   justifyContent="center">
                                <LoadingButton
                                    sx={{width: 180, py: 2}}
                                    startIcon={<Iconify icon="solar:copy-bold-duotone"/>}
                                    color="inherit"
                                    onClick={handleSubmit(() => handleOpenPaymentDialog({
                                        ...data,
                                        confirmation: true
                                    }, INVOICE_TYPE.normal, true))}
                                    variant="contained">
                                    Potvrda
                                </LoadingButton>
                                {OPERATOR.group === OPERATORS_GROUP.admin && <LoadingButton
                                    sx={{width: 180, py: 2}}
                                    startIcon={<Iconify icon="solar:copy-bold-duotone"/>}
                                    color="inherit"
                                    onClick={() => {
                                        if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                            return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                        }
                                        validateAndSubmit(data, handleSubmit(() => handleOpenPaymentDialog(data, INVOICE_TYPE.training)))
                                    }
                                    }

                                    variant="contained">
                                    Obuka
                                </LoadingButton>}
                                <LoadingButton
                                    sx={{width: 180, py: 2}}
                                    startIcon={<Iconify icon="solar:printer-bold-duotone"/>}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                            return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                        }
                                        validateAndSubmit(data, handleSubmit((allProps) => onSubmit(allProps, PAYMENT_TYPE.cash, INVOICE_TYPE.normal)))
                                    }}>
                                    Gotovina brzi
                                </LoadingButton>
                                <LoadingButton
                                    sx={{width: 180, py: 2}}
                                    startIcon={<Iconify icon="solar:printer-bold-duotone"/>}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                            return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                        }
                                        validateAndSubmit(data, handleSubmit((allProps) => onSubmit(allProps, PAYMENT_TYPE.card, INVOICE_TYPE.normal)))
                                    }
                                    }>
                                    Kartica brzi
                                </LoadingButton>
                                <LoadingButton
                                    sx={{width: 180, py: 2}}
                                    startIcon={<Iconify icon="solar:printer-bold-duotone"/>}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        if (data.canceled || !moment.utc(data.departureTripTime, "YYYY-MM-DD HH:mm:ss", "Europe/Belgrade").isAfter(currentTime)) {
                                            return enqueueSnackbar("Polazak je istekao!", {variant: "warning"})
                                        }
                                        validateAndSubmit(data, handleSubmit(() => handleOpenPaymentDialog(data, INVOICE_TYPE.normal)))
                                    }
                                    }>
                                    Fiskalizuj
                                </LoadingButton>
                            </Stack>
                            <Divider sx={{borderStyle: 'dashed'}}/>
                            {data.externalSale === true &&
                                <Stack sx={{mt: 2}} justifyContent="center" alignItems="center" direction="row" spacing={1.2}>
                                    <RHFTextField
                                        label={translate("emailForTicket")}
                                        sx={{
                                            maxWidth: 400
                                        }}
                                        name="emailForTicket"/>
                                    <RHFTextField
                                        label={translate("phoneNumber")}
                                        sx={{
                                            maxWidth: 400
                                        }}
                                        name="phoneNumber"/>
                                </Stack>
                            }
                            <Passenger
                                trip={data}
                                departureTime={moment.utc(data?.departureTripTime)}
                                trafficType={data?.trafficType}
                                open={isOpenIndex === index}
                            />
                            <Services/>
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
            {showVerificationDialog.open &&
                <VerificationDialog
                    freeSeats={data?.freeSeats}
                    onSuccess={onSuccess}
                    open={showVerificationDialog.open}
                    onClose={handleCloseVerificationDialog}
                    invoiceLine={showVerificationDialog.data}
                />
            }
            {showItinerer.open &&
                <ItinererDialog
                    stationTimeAndPath={[]}
                    open={showItinerer.open}
                    onClose={handleCloseItinererDialog}
                    data={showItinerer.data}
                />
            }
            {showPlatformDialog &&
                <PlatformDialog
                    additional={{
                        lineName: data.printText,
                        carrier: data.carrier,
                        journeyDate: moment.utc(data.departureTripTime),
                        platformNumber: data.platformNumber
                    }}
                    onSuccess={onSuccess}
                    open={showPlatformDialog}
                    onClose={handleClosePlatformDialog}
                />
            }

            {showInfoDialog?.open &&
                <InfoDialog
                    open={showInfoDialog.open} data={showInfoDialog.data}
                    onClose={() => setValue("showInfoDialog", {
                        open: false,
                        data: null
                    })}
                />
            }
            {showPriceListDialog?.open && <PriceListDialog
                data={showPriceListDialog}
                onClose={() =>
                    setValue("showPriceListDialog", {
                        open: false,
                        lineId: undefined,
                        rateCardId: undefined,
                        tripId: undefined,
                        operatorName: undefined,
                        lineName: undefined,
                        operatorId: undefined,
                        editable: false,
                        arrStationId: undefined
                    })
                }/>
            }

            {showReturnDialog &&
                <ReturnTripDialog
                    onClose={handleRemoveReturnTrip}
                    onlyClose={() => {
                        setValue("showReturnDialog", false)
                    }}
                    open={showReturnDialog}/>
            }
        </div>
    )
}

export const findFlag = (data) => {
    if (!data) {
        return ""
    }
    let flag = `ic_serbia`
    for (let i = 0; i < COUNTRY_BORDERS.length; i++) {
        if (COUNTRY_BORDERS[i].name.includes(data.toUpperCase())) {
            flag = COUNTRY_BORDERS[i].icon
            break;
        }
    }
    return flag ? `/assets/icons/flags/${flag}.svg` : ""
}

function findStationAndCalculateTimes(objects) {
    for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];

        if (obj.station.id === 1645) {
            if (i === 0) {
                return undefined;
            } else {
                const firstObj = objects[0];
                const currentObj = objects[i];

                return {
                    realDepTime: firstObj.tripDepartureTime,
                    arrTime: addTimes(firstObj.tripDepartureTime, currentObj.departureTime)
                };
            }
        }
    }

    return undefined;
}

function addTimes(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

    let seconds = seconds1 + seconds2;
    let minutes = minutes1 + minutes2 + Math.floor(seconds / 60);
    let hours = hours1 + hours2 + Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}
